/*
 * @Author: LiuBangHua 19922217004@163.com
 * @Date: 2023-07-31 14:12:46
 * @LastEditors: 水熊掌柜 237651519@qq.com
 * @LastEditTime: 2024-02-22 10:17:37
 * @FilePath: \guanwang\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import { monitorZoom } from "../utils/monitorZoom.js";
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const m = monitorZoom();

const routes = [
  {
    path: '/',
    name: 'HomePage',
    meta: {
      title: '水熊掌柜官网—商品一键复制批量修改-订单处理-营销水印'
    },
    component: () => import('../views/HomePage.vue'),
    children:[
      // {
      //   path:'/homePage',
      //   name: '/homePage',
      //   component:() => import('../views/homePage.vue')
      // }
    ]
  },
  {
    path: '*',
    name: 'HomePage',
    meta: {
      title: '水熊掌柜官网—商品一键复制批量修改-订单处理-营销水印'
    },
    component: () => import('../views/HomePage.vue')
  },
  {
    path: '/HomePage',
    name: 'HomePage',
    meta: {
      title: '水熊掌柜官网—商品一键复制批量修改-订单处理-营销水印'
    },
    component: () => import('../views/HomePage.vue')
  },
  {
    path: '/ProductCenter',
    name: 'ProductCenter',
    meta: {
      title: '商品-水熊掌柜官网'
    },
    component: () => import('../views/ProductCenter.vue')
  },
  {
    path: '/StudyCenter',
    name: 'StudyCenter',
    meta: {
      title: '学习中心-水熊掌柜官网'
    },
    component: () => import('../views/StudyCenter.vue')
  },
  {
    path: '/AnnouncementUpdates',
    name: 'AnnouncementUpdates',
    meta: {
      title: '动态公告-水熊掌柜官网'
    },
    component: () => import('../views/AnnouncementUpdates.vue')
  },
  {
    path: '/ContactUs',
    name: 'ContactUs',
    meta: {
      title: '联系我们-水熊掌柜官网'
    },
    component: () => import('../views/ContactUs.vue')
  }
]



const router = new VueRouter({
  mode: 'history',
  base:"/web",
  routes
})
router.beforeEach((to, from, next) => {
  console.log("即将跳转到的路由 ------------------------ >  " + to.name);
  if (to.meta.title) { // 判断是否有标题
    document.title = to.meta.title;
  }
  /* 调整缩放比例 start */
  //针对工控屏调整的缩放比
  if ((to.name === 'HomePage' && window.screen.width <= 1920) || 
      (to.name === 'ProductCenter' && window.screen.width <= 1920) ||
      (to.name === 'StudyCenter' && window.screen.width <= 1920) ||
      (to.name === 'AnnouncementUpdates' && window.screen.width <= 1920) ||
      (to.name === 'ContactUs' && window.screen.width <= 1920)
  ) {
    document.body.style.zoom = 80 / Number(m);//60是对着屏幕具体调的，因屏而异
    next()
  }else {
    document.body.style.zoom = 100 / Number(m);
    next()
  }
  /* 调整缩放比例 end */
})

export default router
