/*
 * @Author: 水熊掌柜 237651519@qq.com
 * @Date: 2024-03-25 18:02:23
 * @LastEditors: zhouxiaoyu 
 * @LastEditTime: 2024-04-30 15:19:06
 * @FilePath: \officialwebsite2\src\api\request.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from 'axios' 
// import { getToken } from '@/getToken'
import { Message } from 'element-ui'
import { showScreenLoading, hideScreenLoading } from "./loading";

console.log('88888',window.location.hostname)

// 创建axios实例
const request = axios.create({
    // 这里可以放一下公用属性等。
    // baseURL: 'http://192.168.10.47:7022', // 用于配置请求接口公用部分，请求时会自动拼接在你定义的url前面。 本地
    // baseURL: 'http://www.cqsxzg.com/', // 用于配置请求接口公用部分，请求时会自动拼接在你定义的url前面。   服务
    withCredentials: false, // 跨域请求时是否需要访问凭证
    timeout: 3 * 1000, // 请求超时时间
 
})

document.title = '水熊掌柜'
 
// 请求拦截器
request.interceptors.request.use((config) => {
//token名称以自己的为定，我的是‘satoken’，如果不需要if这里就可以直接删掉
    // if (getToken('satoken')) {
    //     config.headers['satoken'] = getToken('satoken'); //携带token
    //     config.headers['Content-type'] = 'application/json';
    // }
    showScreenLoading()
 
    return config;
}, (error) => {
    hideScreenLoading()
    return Promise.reject(error)
})
 
request.interceptors.response.use((response) => {
    hideScreenLoading()
    let { status, msg } = response.data
    if (status != 200) {
        Message({ message: msg || 'error', type: 'warning' })
    }
    return response;
}, (error) => {
    hideScreenLoading()
    return Promise.reject(error)
})
 
export default request;