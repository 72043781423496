/*
 * @Author: 周小雨1224 1249855464@qq.com
 * @Date: 2023-08-03 09:51:44
 * @LastEditors: zhouxiaoyu 1249855464@qq.com
 * @LastEditTime: 2023-09-06 11:32:42
 * @FilePath: \officialwebsite2\src\api\homeapi.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from "@/api/request";

// 首页导航
export const getHeadTail = (data) => {
  return request({
      method: 'post',
      url: '/home/api/getHeadTail',
      data: data //可以简写为data
  })
}

// 首页banner图
export const getHome = (data) => {
  return request({
      method: 'post',
      url: '/home/api/getHome',
      data: data //可以简写为data
  })
}

// 获取平台分类
export const getTerraceClassify = (data) => {
  return request({
      method: 'post',
      url: '/home/api/getTerraceClassify',
      data: data //可以简写为data
  })
}
// 产品中心列表
export const getProductList = (data) => {
  return request({
      method: 'post',
      url: '/home/api/getProductList',
      data: data //可以简写为data
  })
}
// 产品详情
export const getProductDetails = (data) => {
  return request({
      method: 'post',
      url: '/home/api/getProductDetails',
      data: data //可以简写为data
  })
}

// 学习中心列表

export const getLearningList = (data) => {
  return request({
      method: 'post',
      url: '/home/api/getLearningList',
      data: data //可以简写为data
  })
}

// 公告动态列表

export const getNotice = (data) => {
  return request({
      method: 'post',
      url: '/home/api/getNotice',
      data: data //可以简写为data
  })
}

// 联系我们
export const getContact = (data) => {
  return request({
      method: 'post',
      url: '/home/api/getContact',
      data: data //可以简写为data
  })
}

// 学习中心详情页
export const getLearningDetails = (data) => {
  return request({
      method: 'post',
      url: '/home/api/getLearningDetails',
      data: data //可以简写为data
  })
}

// 公告详情页
export const getNoticeDetails = (data) => {
  return request({
      method: 'post',
      url: '/home/api/getNoticeDetails',
      data: data //可以简写为data
  })
}