/*
 * @Author: 周小雨1224 1249855464@qq.com
 * @Date: 2023-08-02 18:19:31
 * @LastEditors: 水熊掌柜 237651519@qq.com
 * @LastEditTime: 2024-03-18 11:52:39
 * @FilePath: \officialwebsite2\src\store\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedstate  from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    activeIndex:'1',
    footer:{}
  },
  getters: {
  },
  mutations: {
    setActiveIndex(state,activeIndex){
      state.activeIndex = activeIndex
    },
    setFooter(state, data) {
			state.footer = data
		}
  },
  actions: {
  },
  modules: {
  },
  // vuex持久化插件
  plugins:[
    createPersistedstate({
      // key:'user',// 存数据的key名   自定义的  要有语义化
      // paths: ['loginModule'] // 要把那些模块加入缓存
      reducer(val){
        return {
          // 只储存state中的user
          activeIndex:val.activeIndex,
          footer:val.footer
        }
      }
    }) 
  ]
})
