/*
 * @Author: LiuBangHua 19922217004@163.com
 * @Date: 2023-07-31 14:12:46
 * @LastEditors: 水熊掌柜 237651519@qq.com
 * @LastEditTime: 2024-03-18 12:09:54
 * @FilePath: \officialwebsite\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import { monitorZoom } from "@/utils/monitorZoom.js";
import 'default-passive-events';
import VueClipboard from 'vue-clipboard2'
import VueMeta from 'vue-meta';
 
// 全局挂载
ElementUI.Dialog.props.closeOnClickModal.default = false; //全局禁止dialog点击空白关闭
ElementUI.MessageBox.setDefaults({ closeOnClickModal: false }); //全局禁止messageBox点击空白关闭
Vue.use(VueAwesomeSwiper)
Vue.use(ElementUI);
Vue.use(VueClipboard)
Vue.use(VueMeta);



Vue.config.productionTip = false

// const baseURL = 'http://192.168.10.47:7022'
// Vue.prototype.$baseURL = baseURL;

const m = monitorZoom();
if (window.screen.width * window.devicePixelRatio >= 3840) {
  document.body.style.zoom = 100 / (Number(m) / 2); // 屏幕为 4k 时
} else {
  document.body.style.zoom = 100 / Number(m);
}

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
