/*
 * @Author: zhouxiaoyu 
 * @Date: 2024-04-28 12:28:12
 * @LastEditors: zhouxiaoyu 
 * @LastEditTime: 2024-04-29 16:04:08
 * @FilePath: \officialwebsite2\src\api\loading.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { Loading } from "element-ui";
import _ from 'lodash';
 
let loading = null;
let needRequestCount = 0;
 
 
//开启loading状态
const startLoading = (headers={}) => {
  loading = Loading.service({
    lock: true,   //是否锁定屏幕的滚动
    text: headers.text||"加载中……",  //loading下面的文字
    background: "rgba(0, 0, 0, 0)",  //loading的背景色
    target:headers.target||"body"      //loading显示在容器
  });
};
 
//关闭loading状态
//在关闭loading为了防止loading的闪动，采用防抖的方法，防抖计时一般采用300-600ms
//在关闭loading之后，我们需注意全局变量导致的V8垃圾回收机制，把没用的变量清空为null
const endLoading = _.debounce(() => {
  loading.close();
  loading = null;
},300);
 
 
export const showScreenLoading=(headers)=>{
   if(needRequestCount == 0&&!loading){
    startLoading(headers);
   }
   needRequestCount++;
}
 
export const hideScreenLoading=()=>{
    if(needRequestCount<=0)  return 
    needRequestCount--;
    needRequestCount = Math.max(needRequestCount, 0);
    if(needRequestCount===0){
        endLoading()
    }
}
export default {};