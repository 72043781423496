export const monitorZoom = () => {
    let ratio = 0,
      screen = window.screen,
      ua = navigator.userAgent.toLowerCase();
    if (window.devicePixelRatio !== undefined) {
      ratio = window.devicePixelRatio;
    } else if (~ua.indexOf("msie")) {
      if (screen.deviceXDPI && screen.logicalXDPI) {
        ratio = screen.deviceXDPI / screen.logicalXDPI;
      }
    } else if (
      window.outerWidth !== undefined &&
      window.innerWidth !== undefined
    ) {
      ratio = window.outerWidth / window.innerWidth;
    }
    if (ratio) {
      ratio = Math.round(ratio * 100);
    }
    return ratio;
  };


  // 获取视频时长
  //获取时长
  export const videoUpload = (url) =>{
    console.log('kaishi')
    var audio = new Audio(url)

    console.log('jieshu',audio)

    var duration = 0;
    const formatSeconds = (value)=> {
      var secondTime = parseInt(value);// 秒
      var minuteTime = 0;// 分
      var hourTime = 0;// 小时
      if(secondTime > 60) {//如果秒数大于60，将秒数转换成整数
          //获取分钟，除以60取整数，得到整数分钟
          minuteTime = parseInt(secondTime / 60);
          //获取秒数，秒数取佘，得到整数秒数
          secondTime = parseInt(secondTime % 60);
          //如果分钟大于60，将分钟转换成小时
          if(minuteTime > 60) {
              //获取小时，获取分钟除以60，得到整数小时
              hourTime = parseInt(minuteTime / 60);
              //获取小时后取佘的分，获取分钟除以60取佘的分
              minuteTime = parseInt(minuteTime % 60);
          }
      }
      var result = "" + parseInt(secondTime) + " ";

      if(minuteTime > 0) {
          result = "" + parseInt(minuteTime) + ":" + result;
      }
      if(hourTime > 0) {
          result = "" + parseInt(hourTime) + ":" + result;
      }
      return result;
  }

    audio.addEventListener("loadedmetadata", function (e) {
      duration = formatSeconds(parseInt(audio.duration)) ; // 通过添加监听来获取视频总时长字段，即duration
      console.log("视频时长为", duration);
    });
  }
  